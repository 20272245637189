export const model = {
    id: null,
    name: '',
    last_name: '',
    middle_name: '',
    vacancy_ids:[],
    date_of_birth: null,
    gender_id: null,
    nation_id: null,
    status_id: null,
    phone_number: null,
    min_wage: '',
    max_wage: '',
    email: '',    
    created_at: '',
    updated_at: '',
};
