export const rules = {
  name: [
      { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
  ],
  daly_question_count: [
    { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
  ],
  from_date: [
    { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
  ],
  to_date: [
    { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
  ],
  send_question_time: [
    { required: true, message: 'Пожалуйста, введите название', trigger: 'change' },
  ],
  company_id: [
    { required: true, message: 'Пожалуйста, выберите компанию', trigger: 'change' },
  ],
  branch_id: [
    { required: true, message: 'Пожалуйста, выберите филиал', trigger: 'change' },
  ],
  department_id: [
    { required: true, message: 'Пожалуйста, выберите отдел', trigger: 'change' },
  ],
};
