import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    attendance_report:[],
    staff:null,
    attendance_info:null,
    attendance_work_info:null,
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify({
        page: 1,
        per_page: 5,
        total: 5,
    })),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules   
};
