import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    company : {
      show: true,
      title: i18n.t('message.company'),
      sortable: true,
      column: 'company'
    },
    branch : {
      show: true,
      title: i18n.t('message.branch'),
      sortable: true,
      column: 'branch'
    },
    department : {
      show: true,
      title: i18n.t('message.department'),
      sortable: true,
      column: 'department'
    },
    daly_question_count : {
      show: true,
      title: i18n.t('message.daly_question_count'),
      sortable: true,
      column: 'daly_question_count'
    },
    from_date : {
      show: true,
      title: i18n.t('message.from_date'),
      sortable: true,
      column: 'from_date'
    },
    to_date : {
      show: true,
      title: i18n.t('message.to_date'),
      sortable: true,
      column: 'to_date'
    },
    send_question_time : {
      show: true,
      title: i18n.t('message.send_question_time'),
      sortable: true,
      column: 'send_question_time'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
