import request from '@/utils/request'


export function takeAssent(params) {
    return request({
        url: '/staffs/take_assent',
        method: 'get',
        params
    })
}

export function getReasons(params) {
  return request({
      url: '/settings/staff_assent_reasons',
      method: 'get',
      params
  })
}

export function advanceSalary(params) {
  return request({
      url: '/staffs/advance_salary',
      method: 'get',
      params: params
  })
}

export function complaint(params) {
    return request({
        url: '/staffs/complaint',
        method: 'get',
        params: params
    })
}
export function onlineWork(params) {
  return request({
      url: '/staffs/online-works',
      method: 'get',
      params: params
  })
}
export function vacation(params) {
  return request({
      url: '/staffs/vacation',
      method: 'get',
      params: params
  })
}
export function sickLeave(params) {
  return request({
      url: '/staffs/sick-leaves',
      method: 'get',
      params: params
  })
}
export function upadetStatus(id) {
  return request({
      url: `/staffs/sick-leaves/${id}/back`,
      method: 'post',
  })
}
export function reasons(params) {
  return request({
    url: `/assent/vacation/reasons`,
    method: 'get',
    params
  })
}  
export function sickLeavesReasons(params) {
  return request({
    url: `/assent/sick-leaves/reasons`,
    method: 'get',
    params
  })
}  
export function additional(params) {
  return request({
      url: '/staffs/additional_works',
      method: 'get',
      params: params
  })
}

export function salaryReports(params) {
  return request({
      url: '/staffs/salary_reports',
      method: 'get',
      params: params
  })
}

export function getTelegramAnswerMessage(params) {
  return request({
      url: '/staffs/get-telegram-answer-message',
      method: 'get',
      params
  })
}
