export default [{
    path: '/bonuses',
    name: 'bonuses',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/bonuses/index')
  }]
  

  