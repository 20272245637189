<template>
  <div>
    <div v-if="branches.length > 1" >
      <span class="input--label d-block mb-2" :class="mode ? 'text__day2' : 'text__night2'">
        {{ $t("message.branch") }}
      </span>
      <el-form-item size="medium">
        <el-select
          :value="selected"
          @input="dispatch"
          :multiple="multiple"
          :placeholder="placeholder || $t('message.branch')"
          filterable
          clearable
          :collapse-tags="collapseTags"
          :size="size"
          :style="style"
          class="d-block"
          :class="mode ? 'input__day' : 'input__night'"
        >
          <el-option
            v-for="(company, index) in branches"
            :key="'branches-' + index"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div v-else>
      <el-select
        :value="selected"
        @input="dispatch"
        :multiple="multiple"
        filterable
        clearable
        :placeholder="placeholder || $t('message.branch')"
        :collapse-tags="collapseTags"
        :size="size"
        style="display:none"
      >
        <el-option
          v-for="(company, index) in branches"
          :key="'branches-' + index"
          :label="company.name"
          :value="company.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  name: "select-or-hidden-company",
  props: {
    size: {
      default: "small",
    },
     style: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      default: false,
    },
    collapseTags: {
      default: false,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
        if (this.branches.length == 1) {
          this.dispatch(this.branches[0].id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.branches && this.branches.length == 0)
      this.updateInventory()
        .then((res) => {
          if (res.result.data.branches.length === 1) {
            this.dispatch(res.result.data.branches[0].id);
          }
        })
        .catch((err) => {});
  },
  computed: {
    ...mapGetters({
      branches: "branch/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      // updateInventory: "company/inventory",
      updateInventory: "branch/inventory",
    }),

    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
