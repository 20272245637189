<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.guest_coming_reason')"
      filterable
      clearable
      :disabled="disabled"
      :size="size"
      class="d-block"
      :multiple="multiple"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(guestComingReason, index) in guestComingReasonsList"
        :key="'guestCompanies-' + index"
        :label="guestComingReason.reason"
        :value="guestComingReason.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    guest_id: {
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    guest_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.guestComingReasons && this.guestComingReasons.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      guestComingReasons: "guestComingReason/inventory",
      mode:'MODE'
    }),
    guestComingReasonsList: function () {
      return this.guestComingReasons;
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "guestComingReason/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;     
    },
  },
};
</script>
