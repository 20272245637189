import request from '@/utils/request'

export function index(params) {
  return request({
      url: 'staffs/penalty/get_staff_penalty',
      method: 'get',
      params: params
  })
}

export function inventory(params) {
  return request({
      url: 'staffs/penalty/get_staff_penalty_inventory',
      method: 'get',
      params
  })
}




export function store(data) {
  return request({
      url: `staffs/penalty/add_staff_penalty/${data.staff_id}`,
      method: 'post',
      data
  })
}

export function update(data) {   
  return request({
      url: `staffs/penalty/update_staff_penalty/${data.staff_id}/${data.id}`,
      method: 'post',
      data
  })
}

export function destroy(data) {
  return request({
      url: `staffs/penalty/delete_staff_penalty/${data.staff_id}/${data.id}`,
      method: 'delete',
  })
}
export function show(id) {
  return request({
      url: `staffs/penalty/${id}`,
      method: 'get'
  })
}
export function getFiles(id) {
  return request({
      url: `staffs/penalty/get-file/${id}`,
      method: 'get',
  })
}

export function removeFile(data) {
  return request({
      url: `staffs/penalty/remove-file/${data.id}/${data.penalty_id}`,
      method: 'post',
  })
}
export function downloadFile(data) {
  return request({
      url: `staffs/penalty/download-file/${data.id}/${data.penalty_id}`,
      method: 'get',
      responseType:'blob'
  })
}
