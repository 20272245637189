import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  manager_id: {
    show: true,
    title: i18n.t('message.manager'),
    sortable: true,
    column: 'manager_id'
  },
  periot: {
    show: true,
    title: i18n.t('message.periot'),
    sortable: true,
    column: 'periot'
  },
  amount: {
    show: true,
    title: i18n.t('message.amount_a'),
    sortable: true,
    column: 'amount'
  },
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status'
  },

  comment: {
    show: true,
    title: i18n.t('message.comment'),
    sortable: true,
    column: 'comment'
  },

  created_at: {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: false,
    title: i18n.t('message.updated_at'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
