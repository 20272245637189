import { login, logout, refresh, getAuth, checkStatus, getContract } from "@/api/auth";
import { setToken, getTokenByKey, removeToken, setOtherTokens, removeOtherTokens } from "@/utils/auth";
import axios from "axios";

export const actions = {
    login({ commit }, credentials) {
        const { phone_number, password } = credentials;
        return new Promise((resolve, reject) => {
            login({ phone_number: phone_number.trim(), password: password }).then(response => {                
                const { data } = response.data.result;                
                if (data.token) {                    
                    commit('SET_TOKEN', data.token)                                                                
                    setToken(data.token)
                    setOtherTokens(data.other_tokens)
                    resolve()
                }
            }).catch(error => {                
                reject(error)
            })
        })
    },
    checkStatus({ commit }) {
        return new Promise((resolve, reject) => {
            checkStatus().then(res => {
                resolve(res.data.result.data.status);
            }).catch(error => {
                reject(error)
            })
        })
    },
    getContract({ commit }) {
      return new Promise((resolve, reject) => {
          getContract().then(res => {
            commit('SET_CONTRACT', res.data.result.data.contract)
            resolve(res.data.result.data.contract);
          }).catch(error => {
              reject(error)
          })
      })
    },
    getAuth({ commit, state }, payload) {
        return new Promise((resolve, reject) => {           
            getAuth(state.token).then(res => {                           
                const { data } = res.data.result                
                if (!data) {
                    reject('Проверка не удалась, пожалуйста, войдите снова.')
                }
                const { role, name, phone, goodone_admin_slug } = data
                if (!role) {
                    reject('Роль не может быть пустой')
                }
                commit('SET_SLUG', goodone_admin_slug)
                commit('SET_ROLE', role)
                commit('SET_NAME', name)
                commit('SET_PHONE', phone)                
                commit('SET_USER',res.data.result.data.user)
                console.log('success in permissions js')
                resolve(data)
            }).catch(error => {             
                console.log('error in permissions js')
                commit('SET_TOKEN', '');
                removeToken();
                reject(error);
            })
        })
    },
    refresh({ commit }) {
        refresh().then(res => {
            const { data } = res.data.result;
            if (data.token) {
                commit('SET_TOKEN', data.token)
                setToken(data.token)
            }
        }).catch(err => {
            removeToken()
        })

    },
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            if(getTokenByKey('auth_token_gomax')){
                try{
                    const params = { phone_number: ('+'+state.user.phone_number) };
                    const headers = { 
                        "Authorization": 'Bearer ' + getTokenByKey('auth_token_gomax'),
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
                    };
    
                    axios.post(process.env.VUE_APP_GOMAX_BASE_URL+"/api/auth/logoutFromOtherDomain", params, { headers: headers })
                        .then(res => console.log(res, 'response asdasd'))
                        .catch(err => console.log(err, 'errorrrrrrrrrr'));
                }catch(error){
                    console.log(error, 'gomax logout error' );
                } 
            }
            if(getTokenByKey('auth_token_gocrm')){
                try{
                    const params = { phone_number: ('+'+state.user.phone_number) };
                    const headers = { 
                        "Authorization": 'Bearer ' + getTokenByKey('auth_token_gocrm'),
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE"
                    };
                    axios.post(process.env.VUE_APP_GOCRM_BASE_URL_BACKEND+"/api/auth/logoutFromOtherDomain", params, { headers: headers })
                        .then(res => console.log(res, 'response asdasd'))
                        .catch(err => console.log(err, 'errorrrrrrrrrr'));
                }catch(error){
                    console.log(error, 'gocrm logout error' );
                }
            }
            removeOtherTokens();
            commit('SET_GOMAX_TOKEN', '');
            commit('SET_GOCRM_TOKEN', '');

            logout().then(() => {
                removeToken()
                commit('SET_TOKEN', '')
                commit('SET_ROLE', '')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('SET_TOKEN', '')
            resolve()
        })
    },
}
