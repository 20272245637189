<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'home__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? '' : 'content__titlenight'">{{$t("message.tms")}}</p>
                    <!-- <export-excel
                        v-if="excel_list.length"
                        class="btn excel_btn"
                        :data="excel_list"
                        worksheet="My Worksheet"
                        name="users.xls"
                        :fields="excel_fields"
                      >
                        <el-button size="mini">
                          <i class="el-icon-document-delete"></i>  {{$t("message.excel")}}
                        </el-button>
                      </export-excel> -->
                    <el-button @click="closeModal()" type="warning" plain> {{$t("message.close")}}</el-button>
                </div>
            </div>
            <!-- app-modal__header end -->
           <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? '' : 'performance__itemnight'">
                   <el-row :gutter="20">
                    <el-col :span="24">
                        <h3 :class="mode ? '' : 'content__titlenight'">{{ $t("message.tms") }}</h3>
                        <ul class="tms-info-list" :class="mode ? '' : 'content__titlenight'">
                          <li class="tms-info-item">
                            <b>{{ $t("message.type") }}</b> 
                            <span>{{tms_info.type == "exit"
                                  ? $t("message.exit")
                                  : $t("message.entrance")
                                }}
                            </span>
                          </li>
                          <li class="tms-info-item">
                            <b> {{ $t("message.transport_type") }}</b> 
                            <span>{{ $t('message.' + tms_info.transport_type) }}
                            </span>
                            </li>
                          <li class="tms-info-item">
                            <b>{{ $t("message.car") }}</b> 
                            <span> {{ tms_info.transport ? tms_info.transport.model.model+'-'+tms_info.transport.number : "" }}</span></li>
                          <li class="tms-info-item"><b>{{ $t("message.car_driver") }}</b> <span>{{ tms_info.driver}}</span></li>
                          <li class="tms-info-item"><b> {{ $t("message.left_date_time") }}</b> <span>{{tms_info.left_date+' '+tms_info.left_time}}</span></li>
                          <li class="tms-info-item"><b> {{ $t("message.visit_date_time") }}</b> <span>{{tms_info.arrival_date+' '+tms_info.arrival_time}}</span></li>
                          <li class="tms-info-item"><b> {{ $t("message.region") }}</b> <span>{{tms_info.region?tms_info.region.name:''}}</span></li>
                          <li class="tms-info-item"><b> {{ $t("message.district") }}</b> <span>{{tms_info.district?tms_info.district.name:''}}</span></li>
                          <li class="tms-info-item"><b> {{ $t("message.branch") }}</b> <span>{{tms_info.company?tms_info.company.name:''}}</span></li>
                        </ul>
                      </el-col>
                      <el-col :span="24">
                        <h3 :class="mode ? '' : 'content__titlenight'">{{ $t("message.product") }}</h3>
                        <hr />

                        <!-- <el-table
                            :data="tms_info.items"
                            :class="mode ? '' : 'performance__itemnight'"
                            style="width: 100%" >
                            <el-table-column
                              prop="name"
                              :class="mode ? '' : 'performance__itemnight'"
                              :label="$t('message.product_name')"
                              width="220">
                            </el-table-column>
                            <el-table-column
                              prop="quantity"
                              :label="$t('message.quantity')"
                              width="220">
                            </el-table-column>
                            <el-table-column
                              prop="measurement_type"
                              :label="$t('message.measurement_type')">
                            </el-table-column>
                          </el-table> -->

                          <table class="agents__table" :class="mode ? 'light' : 'night'">
                            <thead>
                              <tr>
                                <th  class="agents__view">{{$t("message.product_name")}}</th>
                                <th  class="agents__view">{{$t('message.quantity')}}</th>
                                <th  class="agents__view">{{$t("message.measurement_type")}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="emp in tms_info.items" :key="emp.id">
                                <td class="agents__view">{{emp.name}}</td>
                                <td class="agents__view">{{emp.quantity}}</td>
                                <td class="agents__view">{{emp.measurement_type}}</td>
                              </tr>
                            </tbody>
                          </table>
                      </el-col>
                      
                    </el-row>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import drawer from "@/utils/mixins/drawer";
export default {
    name: "show-tms",
    mixins: [drawer],
    components: {},
    components: {},
    data() {
        return {
           excel_fields: {
              '№': "id",
              Имя: "name",
              Фамилия: "last_name",
              Отчество: "middle_name",
              Должность: "position",
              Компания: "company",
            },
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        tms_info: "homeStaff/tms_info",
        mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
        getTmsInfo: "homeStaff/getTmsInfo",
    }),

    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getTmsInfo(child.id)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
    

    },
    closeModal() {
      this.$emit("closeTmsModal", false);
    },
     pushToProfile(staff) {
      // this.$router.push({
      //   name: "profile",
      //   params: { id: staff.id },
      // });
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: staff.id,
        },
      });

      window.open(route.href, "_blank");
    },
  }
};
</script>
<style lang="scss" >
.agents__view{
  text-align: left;
}
.agents__table{
  &.night{
    color: #fff;
    th{
      padding: 10px;
      border: 1px solid #807f7f78;
    }
    td{
      padding: 10px;
      border: 1px solid #807f7f78;
    }
  }
  &.light{
    th{
      padding: 10px;
      border: 1px solid #807f7f78;
    }
    td{
      padding: 10px;
      border: 1px solid #807f7f78;
    }
  }
}
.home__night{
  background-color: #0f0e1e !important;
}
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    background-clip: border-box;
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
.tms-info-list{
  list-style: none;
  margin: 0;
  padding: 0;
  .tms-info-item{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
    font-size: 14px;
    padding: 8px 0px;
    border-bottom: 1px solid #cacaca93;
    b{
      width: 35%;
    }
  }
}
</style>
