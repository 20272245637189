<template>
  <div :class="mode ? 'performance__day' : 'performance__night'" class="performance">
    <div class="performers__top">
      <h5>Top Performers</h5>
    </div>
    <el-row>
      <el-col :span="4">
        <div>
          <div class="performance__influ">
            <span>You have 140 <span class="per__bold">influencers</span> in your company</span>
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div>
          <ul class="performance__ul">
            <li v-for="c in 7" :key="c.id" class="performance__item" :class="mode ? 'performance__itemday' : 'performance__itemnight'">
              <div class="performance__flex">
                <div class="performance__image">
                  <img src="https://preview.keenthemes.com/good/assets/media/avatars/300-2.jpg" alt="">
                </div>
                <div class="performance__name">
                  <h5>Melody Mace</h5>
                  <span>@Melody_m</span>
                </div>
                <div class="performance__per">
                  <span>80%</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  }
}
</script>

<style>

</style>
