import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/staffs/profiles',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/staffs/profile/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/staffs/profiles/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/staffs/profiles',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/staffs/profiles/${data.id}`,
      method: 'post',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/staffs/profiles/${id}`,
      method: 'delete',
  })
}

export function addStaffContact(data) {
    return request({
        url: `/staffs/profiles/add_staff_contact/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffContact(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_contact/${staff_id}`,
        method: 'get'        
    })    
}


export function addStaffEducation(data) {
    return request({
        url: `/staffs/profiles/add_staff_education/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffEducation(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_education/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffEducation(data) {
    return request({
        url: `/staffs/profiles/update_staff_education/${data.staff_id}/${data.education_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffEducation(data) {
    return request({
        url: `/staffs/profiles/delete_staff_education/${data.staff_id}/${data.education_id}`,
        method: 'delete'        
    })    
}

// start Skill
export function addStaffSkill(data) {
    return request({
        url: `/staffs/profiles/add_staff_skill/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffSkill(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_skill/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffSkill(data) {
    return request({
        url: `/staffs/profiles/update_staff_skill/${data.staff_id}/${data.skill_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffSkill(data) {
    return request({
        url: `/staffs/profiles/delete_staff_skill/${data.staff_id}/${data.skill_id}`,
        method: 'delete'        
    })    
}


// children Skill
export function addStaffChildren(data) {
    return request({
        url: `/staffs/profiles/add_staff_children/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffChildren(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_children/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffChildren(data) {
    return request({
        url: `/staffs/profiles/update_staff_children/${data.staff_id}/${data.child_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffChildren(data) {
    return request({
        url: `/staffs/profiles/delete_staff_children/${data.staff_id}/${data.child_id}`,
        method: 'delete'        
    })    
}
// Start Status
export function updateStaffStatusAndReason(data) {
    return request({
        url: `/staffs/profiles/update_staff_status/${data.staff_id}`,
        method: 'put',
        data
    })    
}
export function getStaffStatusAndReason(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_status/${staff_id}`,
        method: 'get'        
    })    
}
//End Status
// Start EmploymentTyp
export function updateStaffWorkType(data) {
    return request({
        url: `/staffs/profiles/update_staff_work_type/${data.staff_id}`,
        method: 'put',
        data
    })    
}
export function getStaffWorkType(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_work_type/${staff_id}`,
        method: 'get'        
    })    
}
//End EmploymentTyp
//Document

export function getStaffDocument(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_document/${staff_id}`,
        method: 'get'        
    })    
}

export function uploadStaffDocument(data) {
    return request({
        url: `/staffs/profiles/upload_staff_document/${data.staff_id}`,
        method: 'post',
        data
    })    
}
export function deleteStaffDocument(data) {
    return request({
        url: `/staffs/profiles/delete_staff_document/${data.staff_id}/${data.file_id}`,
        method: 'delete'        
    })    
}
//End of Document

//Document Five

export function getStaffDocumentFive(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_document_five/${staff_id}`,
        method: 'get'        
    })    
}

export function uploadStaffDocumentFive(data) {    
    return request({
        url: `/staffs/profiles/upload_staff_document_five/${data.staff_id}`,
        method: 'post',
        data
    })    
}

//End of Document Five



// WorkPlaces
export function addStaffWorkPlaces(data) {
    return request({
        url: `/staffs/profiles/add_staff_work_place/${data.staff_id}`,
        method: 'post',
        data
    })    
}
export function getStaffWorkPlaces(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_work_place/${staff_id}`,
        method: 'get'        
    })    
}

export function uploadStaffWorkPlaces(data) {
    return request({
        url: `/staffs/profiles/update_staff_work_place/${data.staff_id}/${data.work_place_id}`,
        method: 'put',
        data
    })    
}
export function deleteStaffWorkPlaces(data) {
    return request({
        url: `/staffs/profiles/delete_staff_work_place/${data.staff_id}/${data.work_place_id}`,
        method: 'delete'        
    })    
}
//End of WorkPlaces

export function getStaffWorkHistory(staff_id) {
  return request({
      url: `/staffs/profiles/get_staff_work_history/${staff_id}`,
      method: 'get'        
  })    
}
export function deleteStaffWorkHistory(data) {
  return request({
      url: `/staffs/profiles/delete_staff_work_history/${data.staff_id}/${data.work_history_id}`,
      method: 'delete'        
  })    
}

//  Client Certificates
export function addStaffClientCertificates(data) {
    return request({
        url: `/staffs/profiles/add_staff_client_certificate/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffClientCertificates(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_client_certificate/${staff_id}`,
        method: 'get'        
    })    
}

export function uploadStaffClientCertificates(data) {
    return request({
        url: `/staffs/profiles/update_staff_client_certificate/${data.staff_id}/${data.client_certificate_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffClientCertificates(data) {
    return request({
        url: `/staffs/profiles/delete_staff_client_certificate/${data.staff_id}/${data.client_certificate_id}`,
        method: 'delete'        
    })    
}
//End of Client Certificates


//  Client Certificates
export function addStaffClientHealthInformation(data) {
    return request({
        url: `/staffs/profiles/add_staff_client_health_information/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffClientHealthInformation(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_client_health_information/${staff_id}`,
        method: 'get'        
    })    
}

export function uploadStaffClientHealthInformation(data) {
    return request({
        url: `/staffs/profiles/update_staff_client_health_information/${data.staff_id}/${data.client_health_information_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffClientHealthInformation(data) {
    return request({
        url: `/staffs/profiles/delete_staff_client_health_information/${data.staff_id}/${data.client_health_information_id}`,
        method: 'delete'        
    })    
}
//End of Client Certificates

//Start CLIENT LANGUAGE
export function addStaffLanguage(data) {
    return request({
        url: `/staffs/profiles/add_staff_client_language/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffLanguage(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_client_language/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffLanguage(data) {
    return request({
        url: `/staffs/profiles/update_staff_client_language/${data.staff_id}/${data.language_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffLanguage(data) {
    return request({
        url: `/staffs/profiles/delete_staff_client_language/${data.staff_id}/${data.language_id}`,
        method: 'delete'        
    })    
}
//END CLIENT LANGUAGE

// Start Требования
export function addStaffRequirement(data) {
    return request({
        url: `/staffs/profiles/add_staff_client_requirement/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffRequirement(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_client_requirement/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffRequirement(data) {
    return request({
        url: `/staffs/profiles/update_staff_client_requirement/${data.staff_id}/${data.client_requirement_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffRequirement(data) {
    return request({
        url: `/staffs/profiles/delete_staff_client_requirement/${data.staff_id}/${data.client_requirement_id}`,
        method: 'delete'        
    })    
}
//END Требования
// Start Staff Salary
export function addStaffSalary(data) {
    return request({
        url: `/staffs/profiles/add_staff_salary/${data.staff_id}`,
        method: 'post',
        data
    })    
}
export function updateStaffSalary(data) {
    return request({
        url: `/staffs/profiles/update_staff_salary/${data.staff_id}/${data.id}`,
        method: 'put',
        data
    })    
}

export function getStaffSalary(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_salary/${staff_id}`,
        method: 'get'        
    })    
}

export function deleteStaffSalary(data) {
    return request({
        url: `/staffs/profiles/delete_staff_salary/${data.staff_id}/${data.salary_id}`,
        method: 'delete'        
    })    
}
// END OF STAFF SALARY

// START CARD
export function addStaffCard(data) {
    return request({
        url: `/staffs/profiles/add_staff_card/${data.staff_id}`,
        method: 'post',
        data
    })    
}

export function getStaffCard(staff_id) {
    return request({
        url: `/staffs/profiles/get_staff_card/${staff_id}`,
        method: 'get'        
    })    
}

export function updateStaffCard(data) {
    return request({
        url: `/staffs/profiles/update_staff_card/${data.staff_id}/${data.card_id}`,
        method: 'put',
        data
    })    
}

export function deleteStaffCard(data) {
    return request({
        url: `/staffs/profiles/delete_staff_card/${data.staff_id}/${data.card_id}`,
        method: 'delete'        
    })    
}

//END CARD
export function getBonuses(data) {
    return request({
        url: `/staffs/profiles/${data.staff_id}/bonuses`,
        method: 'get'        
    })    
}

export function getPenalties(data) {
    return request({
        url: `/staffs/profiles/${data.staff_id}/penalties`,
        method: 'get'        
    })    
}
