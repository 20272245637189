import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff : {
        show: true,
        title: i18n.t('message.staff'),
        sortable: true,
        column: 'staff'
    },    
    paid_amount : {
        show: true,
        title: i18n.t('message.amount'),
        sortable: true,
        column: 'paid_amount'
    },
    account : {
        show: true,
        title: i18n.t('message.account'),
        sortable: true,
        column: 'account'
    },
    payment_type : {
        show: true,
        title: i18n.t('message.payment_type'),
        sortable: true,
        column: 'payment_type'
    },
    salary_type : {
        show: true,
        title: i18n.t('message.salary_type'),
        sortable: true,
        column: 'salary_type'
    },
    date_of_end : {
        show: true,
        title: i18n.t('message.payment_date'),
        sortable: true,
        column: 'date_of_end'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
