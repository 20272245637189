import request from '@/utils/request'


export function application(params) {
    return request({
        url: '/staffs/application',
        method: 'get',
        params: params
    })
}

