<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.requirements')"
      filterable
      clearable
      :collapse-tags="collapseTags"
      :size="size"
      class="w100 "
      popper-class="size__input"
      :multiple="multiple"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(requirement, index) in requirements"
        :key="'requirements-' + index"
        :label="requirement.name"
        :value="requirement.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      default: false,
    },
  },
  watch: {
    id: {
      handler: function () {
        if (this.id == null || this.id == "undefined") {
          this.selected = null;
        }
        if (this.id) {
          this.selected = this.id;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.requirements && this.requirements.length === 0)
      this.updateInventory();
  },
  computed: {
    ...mapGetters({
      requirements: "requirement/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "requirement/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
<style>
  .size__input {
    left: auto;
    width: 100px !important;
  }
</style>
