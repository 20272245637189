<template>
  <div  class="new-home-page"  :class="mode ? 'home__day' : 'home__night'" >
    <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page">
          {{ $t(titleStaff) }}
    </div>
    <div class="body-new-home-page">
        <div class="body-new-home-page-number">
            {{ testStaff.count }}
        </div>
        <div
            class="
                body-new-home-page-pratsent
                rise
            "
        >
            <div class="b-avatar">
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="
                        feather
                        feather-trending-up
                    "
                >
                    <polyline
                        points="23 6 13.5 15.5 8.5 10.5 1 18"
                    ></polyline>
                    <polyline
                        points="17 6 23 6 23 12"
                    ></polyline>
                </svg>
            </div>
            <span
                >{{
                    testStaff.percentage
                }}%</span
            >
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'dashboard-block',
  props: ['titleStaff', 'testStaff'],
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  }
}
</script>

<style>

</style>
