export default [{
    path: '/penalties',
    name: 'penalties',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/penalties/index')
  }]
  

  