import request from '@/utils/request'

export function index(params) {
  return request({
    url: 'staffs/attendace_reports',
    method: 'get',
    params: params
  })
}

export function newIndex(params) {
  return request({
    url: 'staffs/attendace-reports-departments',
    method: 'get',
    params: params
  })
}

export function oneStaff(params) {
  return request({
    url: 'staffs/attendace_report_one',
    method: 'get',
    params: params
  })
}
export function getInfo(params) {
  return request({
    url: `staffs/attendace_report_one/${params.id}`,
    method: 'get',
    params: params
  })
}
export function getWorkInfo(params) {
  return request({
    url: `staffs/attendace-report-with-works/${params.id}`,
    method: 'get',
    params: params
  })
}
export function updateOlderAttendance(params) {
  return request({
    url: 'staffs/update-older-attendance',
    method: 'get',
    params: params
  })
}
export function updateOneStaffOlderAttendance(params) {
  return request({
    url: 'staffs/update-one-staff-older-attendance',
    method: 'get',
    params: params
  })
}
export function getOneStaffOneDayAttendance(params) {
  return request({
    url: 'staffs/get-staff-one-day-attendance',
    method: 'get',
    params: params
  })
}

