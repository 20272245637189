import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from '@/store/index'
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';
import { state } from "./state";

export const mutations = {
    SET_LIST: (state, vacancies) => {              
        state.list = vacancies;        
    },
    SET_VACANCY_FOR_CANDIDATE:(state, vacancy_for_candidate) => (state.vacancy_for_candidate = vacancy_for_candidate),
    SET_STEP_CHART:(state, step_chart) => (state.step_chart = step_chart),
    SET_SOCIAL_CHART:(state, social_chart) => (state.social_chart = social_chart),
    SET_STEP_CHART_BY_COUNT:(state, step_chart_by_count) => (state.step_chart_by_count = step_chart_by_count),
    SET_VACANCY_CANDIDATES_LIST:(state,vacancies)=>{state.vacancy_candidate_list=vacancies},
    SET_INVENTORY: (state, vacancies) => (state.inventory = vacancies),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, vacancy) => {
        state.model = vacancy
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    SET_CANDIDATE_PAGINATION: (state, candidate_pagination) => (state.candidate_pagination = candidate_pagination),
    UPDATE_CANDIDATE_PAGINATION: (state, candidate_pagination) => {
        state.candidate_pagination[candidate_pagination.key] = candidate_pagination.value
    },
};
