import { index, inventory, show } from "@/api/hr/userActivity";

export const actions = {
    index({ commit }, params = {}) {       
        return new Promise((resolve, reject) => {         
            index(params)
                .then(res => {                 
                    commit("SET_LIST", res.data.result.data.activities);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.activities);
                })
                .catch(err => {                  
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }) {
        return new Promise((resolve, reject) => {
            inventory().then(res => {                
                commit("SET_INVENTORY", res.data.result.data.models);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.activity);
                    resolve(res.data.result);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
