import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    symbol : {
        show: true,
        title: i18n.t('message.symbol'),
        sortable: true,
        column: 'symbol'
    },
    code : {
        show: true,
        title: i18n.t('message.code'),
        sortable: true,
        column: 'code'
    },
    rate : {
        show: true,
        title: i18n.t('message.currency_y'),
        sortable: true,
        column: 'rate'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
