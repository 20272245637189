import { i18n } from '@/utils/i18n';
export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    }, 
    full_name:{
        show: true,
        title: i18n.t('message.nameFull'),
        sortable: true,
        column: 'full_name'
    },
    company:{
        show: true,
        title: i18n.t('message.company'),
        sortable: true,
        column: 'company'
    },
  //   staff_company:{
  //     show: true,
  //     title: i18n.t('message.company'),
  //     sortable: true,
  //     column: 'staff_company'
  // },
    staff: {
      show: true,
      title: i18n.t('message.guest_coming_reason'),
      sortable: true,
      column: 'staff'
    },
    car:{
        show: true,
        title: i18n.t('message.car'),
        sortable: true,
        column: 'car'
    },
    comment:{
      show: true,
      title: i18n.t('message.comment'),
      sortable: true,
      column: 'comment'
  },
    phone_number:{
        show: true,
        title: i18n.t('message.phone_number'),
        sortable: true,
        column: 'phone_number'
    },
    guest_coming_reason:{
        show: true,
        title: i18n.t('message.guest_coming_reason'),
        sortable: true,
        column: 'guest_coming_reason'
    },
    visit_date:{ 
        show: true,
        title: i18n.t('message.visit_date_time'),
        sortable: true,
        column: 'visit_date'
    },
    visit_time:{
        show: true,
        title: i18n.t('message.visit_time'),
        sortable: true,
        column: 'visit_time'
    },
    left_date:{
        show: true,
        title: i18n.t('message.left_date_time'),
        sortable: true,
        column: 'left_date'
    },
    left_time:{
        show: true,
        title: i18n.t('message.left_time'),
        sortable: true,
        column: 'left_time'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
