import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/settings/business-trips',
      method: 'get',
      params: params
  })
}
export function getStaffList(params) {
  return request({
      url: '/settings/business-trip/staff-list',
      method: 'get',
      params: params
  })
}
export function show(id) {
  return request({
      url: `/settings/business-trips/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/settings/business-trips',
      method: 'post',
      data
  })
}

export function update(data) { 
  return request({
      url: `/settings/business-trips/update/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/settings/business-trips/${id}`,
      method: 'delete',
  })
}
