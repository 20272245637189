<template>
    <div>
        <el-select
            :value="selected"
            @input="dispatch"
            :multiple="multiple"
            :placeholder="placeholder || $t('message.security_locations')"
            filterable
            clearable
            :collapse-tags="collapseTags"
            :size="size"
            class="d-block"
            :class="mode ? 'input__day' : 'input__night'"
        >
            <el-option
                v-for="(company, index) in securityLocations"
                :key="'companies-' + index"
                :label="company.location_name"
                :value="company.id"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
        multiple: {
            default: false,
        },
        collapseTags: {
            default: false,
        },
    },
    watch: {
        id: {
            handler: function () {
                this.selected = this.id;
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            selected: null,
        };
    },
    mounted() {
        if (this.securityLocations && this.securityLocations.length === 0)
            this.updateInventory();
    },
    computed: {
        ...mapGetters({
            securityLocations: "securityLocations/inventory",
            mode:'MODE'
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "securityLocations/inventory",
        }),
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
    },
};
</script>
