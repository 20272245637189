import { 
  getStatistics,
  getNewComeStaffs, 
  getCompleteStaffProfile, 
  getStaffAgeStatistic, 
  getStaffUpdate, 
  getStaffGenderStatistic, 
  getTodayBirthdays,
  getExpectedBirthdays,
  getPastBirthdays,
  getStaffByEducation,
  getStaffByReason,
  getStaffWorkType,
  getWorkExperience,
  getCompleteProfileTheFiliale,
  getEmployeesByRegion,
  getStaffFromCandidate,
  getCandidate,
  getReleaseAndReception,
  getSource,
  getWages,
  getAverageSalaryByBranches,
  getStaffAttendences,
  getFaceIdRegistration,
  getAttendStaffWeek,
  getVisitors,
  getTopManagers,
  getSaffTelegramInformation,
  getEmployeeKpp,
  getTransportKpp,
  getReasonByOperation,
  getTmsStatistic,
  getTmsList,
  getTopToiletAttendants,
  getHasSalaryStaffs,
  getRequestsForToday,
  getStaffByMood,
  getSituationForToday,
  getStaffWithTotalWork
} from "@/api/dashboard/statistics";
export const actions = {
  getStatistics({ commit },params={}) {
    return new Promise((resolve, reject) => {
      getStatistics(params)
          .then(res => {
            commit("SET_STATISTICS", res.data.result.data);                    
            resolve(res.data.result.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
    });
  },
  getNewComeStaffs({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getNewComeStaffs(params)
        .then(res => {
          commit("SET_NEW_COME_STAFFS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getCompleteStaffProfile({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getCompleteStaffProfile(params)
        .then(res => {
          commit("SET_COMPLETE_STAFF_PROFILE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffAgeStatistic({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffAgeStatistic(params)
        .then(res => {
          commit("SET_STAFF_AGE_STATISTIC", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getHasSalaryStaffs({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getHasSalaryStaffs(params)
        .then(res => {
          commit("SET_HAS_SALARY_STAFFS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getRequestsForToday({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getRequestsForToday(params)
        .then(res => {
          const info = Object.keys(res.data.result.data.requestsForToday).map(key => ({...res.data.result.data.requestsForToday[key], title: key}))
          commit("SET_REQUEST_FOR_TODAY", info);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getSituationForToday({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getSituationForToday(params)
        .then(res => {
          const info = Object.keys(res.data.result.data.situationForToday).map(key => ({...res.data.result.data.situationForToday[key], title: key}))
          commit("SET_SITUATION_FOR_TODAY", info);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffByMood({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffByMood(params)
        .then(res => {
          commit("SET_STAFF_BY_MOOD", res.data);
          resolve(res.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffUpdate({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffUpdate(params)
        .then(res => {
          commit("SET_STAFF_UPDATE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffGenderStatistic({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffGenderStatistic(params)
        .then(res => {
          commit("SET_GENDER_STATISTIC", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getTodayBirthdays({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTodayBirthdays(params)
        .then(res => {
          commit("SET_TODAY_BIRTHDAYS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getExpectedBirthdays({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getExpectedBirthdays(params)
        .then(res => {
          commit("SET_EXPECTED_BIRTHDAYS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getPastBirthdays({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getPastBirthdays(params)
        .then(res => {
          commit("SET_PAST_BIRTHDAYS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffByEducation({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffByEducation(params)
        .then(res => {
          commit("SET_STAFF_BY_EDUCATION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffByReason({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffByReason(params)
        .then(res => {
          commit("SET_STAFF_BY_REASON", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getTopManagers({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTopManagers(params)
        .then(res => {
          commit("SET_TOP_MANAGERS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffWorkType({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffWorkType(params)
        .then(res => {
          commit("SET_STAFF_WORK_TYPE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getWorkExperience({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getWorkExperience(params)
        .then(res => {
          commit("SET_WORK_EXPERIENCE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getCompleteProfileTheFiliale({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getCompleteProfileTheFiliale(params)
        .then(res => {
          commit("SET_COMPLETE_PROFILE_THE_FILIALE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getEmployeesByRegion({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getEmployeesByRegion(params)
        .then(res => {
          commit("SET_EMPLOYEES_BY_REGION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getEmployeesByRegion({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getEmployeesByRegion(params)
        .then(res => {
          commit("SET_EMPLOYEES_BY_REGION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getEmployeesByRegion({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getEmployeesByRegion(params)
        .then(res => {
          commit("SET_EMPLOYEES_BY_REGION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffFromCandidate({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffFromCandidate(params)
        .then(res => {
          commit("SET_STAFF_FROM_CANDIDATE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getCandidate({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getCandidate(params)
        .then(res => {
          commit("SET_CANDIDATE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getReleaseAndReception({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getReleaseAndReception(params)
        .then(res => {
          commit("SET_RELEASE_AND_RECEPTION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getEmployeeKpp({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getEmployeeKpp(params)
        .then(res => {
          commit("SET_EMPLOYEE_KPP", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getSource({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getSource(params)
        .then(res => {
          commit("SET_SOURCE", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getWages({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getWages(params)
        .then(res => {
          commit("SET_WAGES", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getAverageSalaryByBranches({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getAverageSalaryByBranches(params)
        .then(res => {
          commit("SET_AVERAGE_SALARY_BY_BRANCHES", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffAttendences({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffAttendences(params)
        .then(res => {
          commit("SET_STAFF_ATTENDENCES", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getFaceIdRegistration({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getFaceIdRegistration(params)
        .then(res => {
          commit("SET_FACE_ID_REGISTRATION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getAttendStaffWeek({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getAttendStaffWeek(params)
        .then(res => {
          commit("SET_ATTEND_STAFF_WEEK", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getSaffTelegramInformation({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getSaffTelegramInformation(params)
        .then(res => {
          commit("SET_STAFF_TELEGRAM_INFORMATION", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getVisitors({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getVisitors(params)
        .then(res => {
          commit("SET_VISITORS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getTransportKpp({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTransportKpp(params)
        .then(res => {
          commit("SET_TRANSPORT_KPP", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getReasonByOperation({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getReasonByOperation(params)
        .then(res => {
          commit("SET_REASON_OPERATIONS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },

  getTmsStatistic({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTmsStatistic(params)
        .then(res => {
          commit("SET_TMS_STATISTIC", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getTmsList({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTmsList(params)
        .then(res => {
          commit("SET_TMS_LIST", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },

  getTopToiletAttendants({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTopToiletAttendants(params)
        .then(res => {
          commit("SET_TOP_TOILET_ATTENDANTS", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
  getStaffWithTotalWork({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getStaffWithTotalWork(params)
        .then(res => {
          commit("SET_STAFF_TOTAL_WORK", res.data.result.data);
          resolve(res.data.result.data)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },
};
