<template>
  <div>
    <el-select :class="mode ? 'input__day' : 'input__night'" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.graphic')" filterable clearable
      :size="size" class="d-block">
      <el-option v-for="(graphicType,index) in graphicTypes" :key="'graphicTypes-' + index" :label="graphicType.name" :value="graphicType.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import { i18n  } from '@/utils/i18n';
  export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
       id:{
          default: null
        }
    },
     watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.graphicTypes && this.graphicTypes.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        graphicTypes: 'graphicType/inventory',
        mode:'MODE'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'graphicType/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
