export default [
  
    {
      path: '/new-structure',
      name: 'newStructure',
      meta: {
          layout: 'main'
      },
      component: () => import('@/views/structure/newStructure')
  },
    {
        path: '/structure',
        name: 'structure',
        meta: {
            layout: 'main'
        },
        component: () => import('@/views/structure/index')
    },
    {
        path: '/structure/:key/:id',
        name: 'structure.show',
        meta: {
        layout: 'main'
        },
        component: () => import('@/views/structure/show')
    }
]
