export default [
  {
    path: "/attendance",
    name: "attendance",
    meta: {
      layout: "main"
    },
    component: () => import("@/views/attendance/index")
  }
];
