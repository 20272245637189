import request from '@/utils/request'

export function index(params) {
    return request({
        url: 'visitors/visitors',
        method: 'get',
        params: params
    })
}

export function inventory(params) {
    return request({
        url: 'visitors/visitor/inventory',
        method: 'get',
        params: params
    })
}

export function show(id) {
    return request({
        url: `/visitors/visitors/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: `visitors/visitors`,
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `visitors/visitors/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `visitors/visitors/${id}`,
        method: 'delete',
    })
}

export function updateDates(data) {
    return request({
        url: `visitors/visitor/update_dates/${data.id}`,
        method: 'put',
        data
    })
}
