import { getToken, getTokenByKey } from "@/utils/auth";

export const state = {
    token: getToken(),
    gomax_token: getTokenByKey('auth_token_gomax'),
    gocrm_token: getTokenByKey('auth_token_gocrm'),
    name: '',
    phone_number: '',
    role: null,
    expires_in: 600000,
    slug: '',
    status: null,   
    user:{},
    contract:[],
};
