export const model = {
  id: null,
  left_time: null,
  left_date: null,
  staff_id: null,
  transport_id: null,
  transport_type: 'company_transport',
  type: 'entrance',
  created_at: '',
  updated_at: '',
  items: [{
    name: null,
    measurement_type: null,
    quantity: null
  }]
};
