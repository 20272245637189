import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
export const rules = {
  name: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
      { min: 3, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' }
  ],
  
  user_role_id: [
    { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' }   
],
status_id: [
  { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' }   
],
  last_name: [
    { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    { min: 3, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' }
  ],
  middle_name: [
    { required: false, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    { min: 3, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' }
  ],
  gender_id: [
    { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' }
  ],
  email: [
    { required: false, email, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    { min: 3, max: 255, message: 'Длина должна быть от 3 до 255', trigger: 'blur' }
  ],
  phone_number: [
      { required: true, validator: 'Пожалуйста, введите телефон', trigger: 'blur'},
      { min: 12, max: 12, message: 'Длина должна быть 13', trigger: 'blur' }
  ],
  password: [
      { required: true, message: 'Пожалуйста, введите пароль', trigger: 'blur' },
      { min: 6, max: 255, message: 'Длина должна быть от 6 до 255', trigger: 'blur' },
  ],
  password_confirmation: [
    { required: true, message: 'Пожалуйста, введите пароль', trigger: 'blur', sameAsPassword: sameAs('password') }     
  ],
    
};
