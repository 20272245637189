export const getters = {
    list: state => state.list,
    reasons: state => state.reasons,
    staffAttendance: state => state.staffAttendance,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    paginationAttendance: state => state.paginationAttendance,
    sort: state => state.sort
};
