<template>
    <div class="builder__box">
        <div class="builder__into" :class="mode ? 'builder__intoday' : 'builder__intonight'">
            <div>
                <div class="builder__title">
                <h5 :class="mode ? 'text__day' : 'text__night'"> {{ $t("message.display_and_wallpaper") }} </h5>
            </div>
            <span class="builder__text mb-2" :class="mode ? 'text__day' : 'text__night'">Расположения</span>
                <div class="builder__mode mb-3" :class="mode ? 'builder__modeday' : 'builder__modenight'">
                    <div class="shortcuts__list">
                        <el-radio-group v-model="second" class="shortcuts__odd">
                            <li class="shortcuts__item" v-for="c in position" :key="c.id">
                                <el-radio :label="c.id" class="shortcuts__label" >
                                    <div class="shortcuts__flex">
                                        <div class="builder__image">
                                            <img :src="c.src" alt="">
                                        </div>
                                        <div class="shortcuts__name">
                                            <span :class="mode ? 'text__day' : 'text__night'" class="text-hover-primary mb-1">{{ c.name }}</span>
                                        </div>
                                    </div>
                                </el-radio>
                                <div class="builder__seperator"></div>
                            </li>
                        </el-radio-group>
                    </div>
                </div>
                <span class="builder__text mb-2" :class="mode ? 'text__day' : 'text__night'">{{ $t("message.dynamic_wallpaper") }}</span>
                <div class="builder__wall mb-3" v-loading="isLoaded" :class="mode ? 'builder__modeday' : 'builder__modenight'">
                    <el-radio-group v-model="third"  class="builder__ul">
                        <li v-for="back in wallpapers" :key="back.id" class="shortcuts__item1" >
                            <label class="shortcuts__label1">
                                <div class="mode__image1" :class="mode ? 'mode__shadowday' : 'mode__shadownight'">
                                    <img :src="back.resize" alt="" @load="onImgLoad">
                                </div>
                                <div class="builder__name">
                                <el-radio :class="mode ? 'text__day' : 'text__night'" :label="back">{{ back.name }}</el-radio>
                                </div>
                            </label>
                        </li>
                    </el-radio-group>
                </div>
                <span v-show="error" class="red__error"> {{ $t("message.please_select") }}</span>
            </div>
            <div slot="footer" class="dialog__footer pb-2">
                <el-button class="btn__full" type="warning" :plain="mode ? true : false" @click="close()">{{ $t("message.close") }}</el-button>
                <el-button @click="submitHandler()" class="btn__full" type="success" :plain="mode ? true : false">{{ $t("message.save") }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import drawer from '../../utils/mixins/drawer'
import { mapGetters } from "vuex";

export default {
    mixins: [drawer],
    props: [],
    data: () => ({
        first: false,
        error: false,
        second: 1,
        isLoaded: true,
        automatic: false,
        third: '',
        position: [
            {
                id: 1,
                src: 'https://htmlstream.com/front-dashboard/assets/svg/layouts/sidebar-compact.svg',
                name: 'Новая версия'
            },
            {
                id: 2,
                src: 'https://htmlstream.com/front-dashboard/assets/svg/layouts/header-double-line-fluid.svg',
                name: 'Старая версия'
            }
        ],
        wallpapers: [
            {
                id: 115,
                check: false,
                url: '/img/background/without.jpg',
                resize: '/img/background/without-min.png',
                name: 'White',
                mode: true
            },
            {
                id: 66,
                check: false,
                url: '/img/background/22.png',
                resize: '/img/background/22-min.png',
                name: 'Building',
                mode: true
            },
            {
                id: 44,
                check: false,
                url: '/img/background/6.jpg',
                resize: '/img/background/4-min.png',
                name: 'Tree',
                mode: true
            },
            {
                id: 55,
                check: true,
                url: '/img/background/33.jpg',
                resize: '/img/background/33-min.png',
                name: 'Crocket',
                mode: true
            },
            {
                id: 113,
                check: false,
                url: '/img/background/line.jpg',
                resize: '/img/background/line-min.png',
                name: 'Line',
                mode: true
            },

            {
                id: 114,
                check: false,
                url: '/img/background/blur.jpg',
                resize: '/img/background/blur-min.png',
                name: 'Music',
                mode: true
            },
            {
                id: 116,
                check: false,
                url: '/img/background/black.jpg',
                resize: '/img/background/black-min.png',
                name: 'Black',
                mode: false
            },
            {
                id: 77,
                check: false,
                url: '/img/background/10.jpg',
                resize: '/img/background/10-min.png',
                name: 'Buble',
                mode: false
            },
            {
                id: 88,
                check: false,
                url: '/img/background/8.jpg',
                resize: '/img/background/8-min.png',
                name: 'Camera',
                mode: false
            },
            {
                id: 99,
                check: false,
                url: '/img/background/9.jpg',
                resize: '/img/background/9-min.png',
                name: 'Virus',
                mode: false
            },
            {
                id: 111,
                check: false,
                url: '/img/background/pyramid.jpg',
                resize: '/img/background/pyramid-min.png',
                name: 'Spiral',
                mode: false
            },
            {
                id: 112,
                check: false,
                url: '/img/background/renato.jpg',
                resize: '/img/background/renato-min.png',
                name: 'Wave',
                mode: false
            },


        ]
    }),
    mounted () {
    },
    methods: {
        beforeClose (done) {
            this.customBuilder = false
        },
        afterOpen () {
            this.first = this.mode
            this.second = this.radio
        },
        onImgLoad () {
          setTimeout(() => this.isLoaded = false, 500);
          
        },
        submitHandler () {
          console.log(this.first)
            const info = {
                layout: this.second,
                mode: this.first,
                background: this.third,
            }
            console.log(info)
            if (this.second == 1) {
                if (info.background) {
                    this.$store.commit('builderSpan', info)
                    this.close()
                } else {
                    this.error = true
                }
            } else if (this.second == 2) {
                this.$store.commit('builderSpan', info)
                this.close()
            } else {
                this.$store.commit('builderSpan', info)
                this.close()
            }
            this.$router.go(this.$router.currentRoute)

        }
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            oldVersion: "OLDVERSION",
            leftVersion: "LEFTVERSION",
            openVersion: "OPENVERSION",
            background: "BACKGROUND",
            radio: 'RADIO'
        }),
    }
}
</script>

<style scoped>
.builder__box {
    height: 100%;
}
.builder__title h5 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.btn__full {
  width: 100%;
}
.builder__mode {
    padding: 10px 15px;
    border-radius: 0.475rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
}
.builder__modenight {
    background: #323248 !important;
}
.builder__modeday{
    background: #fff !important;
}
.builder__wall {
    background: #fff;
    padding: 10px 15px;
    border-radius: 0.475rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-height: 100px;
    max-height: 410px;
    overflow-y: auto;
    margin-top: 10px;
}
.mode__shadowday {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mode__shadownight {
    box-shadow: none;
}
.mode__shadownight img {
    box-shadow: none !important;
}
.red__error {
    color: red;
}
.builder__wall::-webkit-scrollbar {
  width: 8px;
}
.builder__wall {
  scrollbar-width: thin;
  scrollbar-color: #ebeef5;
}
.builder__wall::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}
.builder__wall::-webkit-scrollbar-thumb {
  background-color: #ebeef5;
  border-radius: 10px;
  border: 2px solid #fff0;
}
.builder__wall:hover::-webkit-scrollbar-thumb {
  background-color: #d1d7e4;
  border-radius: 10px;
  border: 2px solid #fff0;
}
.text__night {
    color: #fff;
}
.text__day {
    color: #000;
}

.builder__into {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.builder__intoday {
    background: #fff;
}
.builder__intonight {
    background: #100e1d;
}
.dialog__footer {
    display: flex;
    flex-direction: row;
}
.btn__cancel {
    width: 100%;
    margin-right: 5px;
    background: #ebb563;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #ebb563;
    padding: 8px 0;
}

.btn__submit {
    width: 100%;
    margin-left: 5px;
    background: #0071e3;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #0071e3;
    padding: 8px 0;
}
.btn__submit:hover {
    background: #0077ed;
}
.builder__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}
.shortcuts__input {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50% !important;
    margin-right: 10px;
}
.shortcuts__input:focus-visible {
    outline-width: 0;
}
.shortcuts__name {
    display: flex;
    align-items: center;
}
.shortcuts__name span {
    padding-left: 20px;
    font-size: 14px;
    text-align: left;
}
.builder__name {
    display: flex;
    margin-top: 15px;
}
.shortcuts__odd {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
}
.shortcuts__item {
    display: flex;
    align-items: center;
    margin: 5px 0;
}
.shortcuts__item1 {
    display: flex;
    align-items: center;
    margin: 5px 0;
    width: 31%;
}
.shortcuts__item2 {
    display: flex;
    align-items: center;
    margin: 0px 0;
    width: 47%;
}
.shortcuts__label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.builder__seperator {
    display: block;
    border-bottom: 1px solid #e8e5dd;
    border-bottom-style: dashed;
    border-bottom-color: #e8e5dd;
    margin: 10px 0;
}
.builder__svg {
    width: 100%;
    height: 100%;
}
.builder__row {
    width: 33%;
}
.builder__into {
    padding: 20px 15px;
}
.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
}
.builder__item span {
    font-size: 14px;
}
.shortcut__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
}
.shortcut__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
}
.shortcut__footer h5 {
    margin: 0;
    line-height: 16px;
    font-size: 14.95px;
    font-weight: 500;
    margin-top: 5px;
}
.shortcut__footer span {
    font-weight: 600;
    font-size: 17.55px;
}
.shortcut__view i {
    color: #50cd89;
    background-color: #2d3e35;
    padding: 4px 8px;
    border-radius: 20%;
}
.shortcut__viewday i {
    color: #fff;
    background-color: #50cd89;
    padding: 4px 8px;
    border-radius: 20%;

}
.shortcuts__image {
    border: 1px dashed #323248;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12.5px;
}
.shortcuts__image img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}
.drawer__back div div {
    background-color: #ffffff !important;
}
.shortcuts__title span {
    color: #fff;
    font-size: 19.5px;
}

.builder__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: auto;
}
.builder__image img {
    width: 100%;
    border-radius: 0.475rem;
    object-fit: contain;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mode__image {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}
.mode__image img {
    width: auto;
    height: 100px;
    border-radius: 0.475rem;
    object-fit: contain;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mode__image1 {
    width: 100%;
    height: 80px;
    display: flex;
    object-fit: contain;
    border-radius: 0.475rem;

}
.mode__image2 {
    width: 100%;
    height: 120px;
    display: flex;
    object-fit: contain;
}
.mode__image2 img {
    width: 100%;
    height: 100%;
    border-radius: 0.475rem;
    object-fit: cover;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.mode__image1 img {
    width: 100%;
    height: 100%;
    border-radius: 0.475rem;
    object-fit: cover;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.builder__ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}
.shortcuts__label {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.shortcuts__label1 {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}
.builder {
    position: fixed;
    right: 0;
    margin-right: -39px !important;
    bottom: 50%;
    top: 50%;
    z-index: 99;
}
.builder__item {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg);
    background-color: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 0 0 0.45rem 0.45rem;
}
.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
    color: #fff;
}
.builder__item:hover {
    color: #fff;
}
.builder__item span {
    font-size: 14px;
    color: #fff;
}
.shortcuts__odd {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
}
.builder__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
}
.flex__between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.builder__text {
    font-size: 14px;
    font-weight: 600;
}
.shortcuts__flex {
    display: flex;
    flex-direction: row;
}
</style>
