import request from '@/utils/request'

export function index(params) {
  return request({
      url: `/settings/vacation-plane-staffs/${params.route_id}`,
      method: 'get',
      params: params
  })
}


// export function inventory(params) {
//   return request({
//       url: '/settings/vacation-planes/inventory',
//       method: 'get',
//       params
//   })
// }

// export function show(id) {
//   return request({
//       url: `/settings/vacation-planes/${id}`,
//       method: 'get'
//   })
// }
// export function getStaffInfo(params) {
//   return request({
//       url: `/settings/vacation-management/staff/${params.id}`,
//       method: 'get',
//       params: params
//   })
// }

// export function store(data) {
//   return request({
//       url: '/settings/vacation-planes',
//       method: 'post',
//       data
//   })
// }



export function destroy(id) {
  return request({
      url: `/settings/vacation-planes/${id}`,
      method: 'delete',
  })
}
export function getStaffs(id) {
  return request({
      url: `/settings/vacation-plane-staffs/${id}`,
      method: 'get'
  })
}
export function saveStaffList(data) {
  return request({
      url: `/settings/vacation-plane-staffs/${data.id}/update`,
      method: 'post',
      data
  })
}
export function updateStaffList(data) {
  return request({
      url: `/settings/vacation-plane-staffs/update/${data.id}`,
      method: 'post',
      data
  })
}
