import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/quiz/quiz-plans',
      method: 'get',
      params: params
  })
}

export function inventory(params) {
  return request({
      url: '/quiz/question/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/quiz/quiz-plans/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/quiz/quiz-plans',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/quiz/quiz-plans/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/quiz/quiz-plans/${id}`,
      method: 'delete',
  })
}
