const TokenKey = 'auth_token';

const GOMAXTokenKey = 'auth_token_gomax';
const GOCRMTokenKey = 'auth_token_gocrm';

export function setToken(token) {   
    return localStorage.setItem(TokenKey, token)
}

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function removeToken() {    
    return localStorage.removeItem(TokenKey)
}

export function getTokenByKey(keyName) {
    return localStorage.getItem(keyName)
}

export function setOtherTokens(tokens) {    
    localStorage.setItem(GOMAXTokenKey, tokens.gomax_token)
    localStorage.setItem(GOCRMTokenKey, tokens.gocrm_token)
}

export function removeOtherTokens() {    
    localStorage.removeItem(GOMAXTokenKey)
    localStorage.removeItem(GOCRMTokenKey)
}
