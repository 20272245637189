export const state = {   
    cards: [],
    penalties: [],
    bonuses: [],
    advances: [],
    salary:{},
    work_salary:'',
    graphic:{},
    transactions:[],
    payment_months:[],
    start_and_end_dates:[]   
};
