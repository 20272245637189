<template>
  <div>
    <el-select :class="mode ? 'input__day' : 'input__night'" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.reason')" filterable clearable :multiple="multiple"
      :size="size" class="d-block">
      <el-option v-for="(reason,index) in penaltyReasons" :key="'reasons-' + index" :label="reason.name" :value="reason.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import { i18n  } from '@/utils/i18n';
  export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
        id:{
          default: null
        },
        type:{
          default: null
        },
        multiple:{
            type: Boolean,
            default: false,
        }
    },
     watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      type:{
        handler: function (newVal, oldVal) {
          if (newVal != oldVal) {
            this.selected = null;
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      const param = {
        'reason_type': this.type,
      };
      if (this.penaltyReasons && this.penaltyReasons.length === 0) this.updateInventory(param)
    },
    computed: {
      ...mapGetters({
        penaltyReasons: 'penaltyReasons/sanction_inventory',
        mode:'MODE'
      }),
      items: function () {
      return !this.type
        ? this.departments
        : _.filter(this.penaltyReasons, ["type", this.type]);
    },
    },
    methods: {
      ...mapActions({
        updateInventory: 'penaltyReasons/sanction'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
