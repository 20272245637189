export const model = {
  id: null,
  left_time: null,
  left_date: null,
  staff_id: null,
  transport_id: null,
  type: 'entrance',
  created_at: '',
  updated_at: '',
};
