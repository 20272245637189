export default [{
    path: '/accounts',
    name: 'accounts',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/accounts/index')
  }]
  

  