import {
    i18n
} from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: "id",
    },

    name: {
        show: true,
        title: i18n.t('message.nameFull'),
        sortable: true,
        column: "name",
    },

    last_name: {
        show: true,
        title: i18n.t('message.last_name'),
        sortable: true,
        column: "last_name",
    },

    middle_name: {
        show: false,
        title: i18n.t('message.middle_name'),
        sortable: true,
        column: "middle_name",
    },
    salary: {
      show: false,
      title: i18n.t('message.salary'),
      sortable: true,
      column: "salary",
  },

    phone_number: {
        show: true,
        title: i18n.t('message.phone_number'),
        sortable: true,
        column: "phone_number",
    },

    email: {
        show: false,
        title: i18n.t('message.email'),
        sortable: true,
        column: "email",
    },
    position_ids : {
      show: false,
      title: i18n.t('message.position_2'),
      sortable: true,
      column: 'position_ids'
  },
    gender_id: {
        show: true,
        title: i18n.t('message.gender'),
        sortable: true,
        column: "gender_id",
    },

    nation_id: {
        show: true,
        title: i18n.t('message.nation'),
        sortable: true,
        column: "nation_id",
    },

    status_id: {
        show: false,
        title: i18n.t('message.status'),
        sortable: true,
        column: "status_id",
    },

    work_type_id: {
      show: false,
      title: i18n.t('message.work_type'),
      sortable: true,
      column: "work_type_id",
  },

    graphic_id: {
        show: true,
        title: i18n.t('message.graphic'),
        sortable: true,
        column: "graphic_id",
    },

    position_id: {
        show: true,
        title: i18n.t('message.position'),
        sortable: true,
        column: "position_id",
    },

    department_id: {
        show: true,
        title: i18n.t('message.department'),
        sortable: true,
        column: "department_id",
    },

    reason_id: {
        show: false,
        title: i18n.t('message.reason'),
        sortable: true,
        column: "reason_id",
    },

    date_of_birth: {
        show: true,
        title: i18n.t('message.date_of_birth'),
        sortable: true,
        column: 'date_of_birth'
    },

    hiring_date: {
        show: false,
        title: i18n.t('message.hiring_date'),
        sortable: true,
        column: 'hiring_date'
    },


    passport_number: {
        show: true,
        title: i18n.t('message.passport_number'),
        sortable: true,
        column: 'passport_number'
    },

    passport_issued_by: {
        show: false,
        title: i18n.t('message.passport_issued_by'),
        sortable: true,
        column: 'passport_issued_by'
    },

    passport_valid_until: {
        show: false,
        title: i18n.t('message.passport_valid_until'),
        sortable: true,
        column: 'passport_valid_until'
    },


    inn: {
        show: true,
        title: i18n.t('message.pnfl'),
        sortable: true,
        column: 'inn'
    },
    telegram: {
      show: true,
      title: i18n.t('message.telegram'),
      sortable: true,
      column: 'telegram'
    },
    telegram_staff: {
      show: true,
      title: i18n.t('message.telegram_staff'),
      sortable: true,
      column: 'telegram_staff'
    },
    izn_password: {
      show: false,
      title: i18n.t('message.izn_password'),
      sortable: true,
      column: 'izn_password'
    },
    children: {
      show: false,
      title: i18n.t('message.children'),
      sortable: true,
      column: 'children'
    },

    category: {
      show: true,
      title: i18n.t('message.category'),
      sortable: true,
      column: 'category'
    },

    district_id: {
        show: false,
        title: i18n.t('message.district_id'),
        sortable: true,
        column: 'district_id'
    },



    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },

    updated_at: {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },

    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }

};
