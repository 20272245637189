import request from '@/utils/request'

export function show(id) {
  return request({
      url: `/open-staff-profile/${id}`,
      method: 'get'
  })
}
export function oneStaff(params) {
  return request({
      url: `/open-staff-profile/attendace-report/${params.uuid}`,
      method: 'get',
      params:{'select_date':params.select_date}
  })
}
export function acquaintedRequirement(params) {
  return request({
      url: `/open-staff-profile/staff-client-requirement/update/${params.uuid}`,
      method: 'post',
  })
}
export function getStaffRequirement(staff_id) {
  return request({
      url: `/open-staff-profile/staff-client-requirement/${staff_id}`,
      method: 'get'        
  })    
}
export function getStaffContact(staff_id) {
  return request({
      url: `/open-staff-profile/get_staff_contact/${staff_id}`,
      method: 'get'        
  })    
}
