export const mutations = {     
    
    SET_STAFF_CARDS:(state,cards)=>{
        state.cards=cards
    },
    SET_STAFF_PENALTIES:(state,penalties)=>{
        state.penalties=penalties
    },
    SET_STAFF_BONUSES:(state,bonuses)=>{
        state.bonuses=bonuses
    },
    SET_STAFF_ADVANCE:(state,advances)=>{
      state.advances=advances
    },
    SET_STAFF_SALARY: (state,salary)=>{
        state.salary=salary
    },
    SET_STAFF_WORK_SALARY: (state,work_salary)=>{
      state.work_salary=work_salary
    },
    SET_STAFF_GRAPHIC: (state,graphic)=>{
        state.graphic=graphic
    },
    SET_STAFF_TRANSACTIONS:(state,transactions)=>{
        state.transactions=transactions;
    },
    SET_STAFF_PAYMENT_MONTHS:(state,payment_months)=>{
        state.payment_months=payment_months;
    },
    SET_STAFF_START_AND_END_DATES:(state,start_and_end_dates)=>{
        state.start_and_end_dates=start_and_end_dates
    }
};
