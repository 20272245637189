<template>
  <div id="app">
    <div class="app-wrap d-flex f-column">
      <component v-show="!lockStatus" :is="layout">
        <router-view />
      </component>
      <twostep v-if="lockStatus" :show-lock="lockStatus"></twostep>
    </div>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import LockScreenMixin from './utils/mixins/LockScreenMixin'
import twostep from './views/twostep/index.vue'
import {mapGetters} from 'vuex';
export default {
  mixins: [LockScreenMixin],
  mounted(){
    let date = new Date()
    let today = `${date.getDate()} ${(date.getMonth()+1)} ${date.getFullYear()}`;
    this.NEW_DAY_CHECK({date: today});
  },
  watch: {
      idle(newValue) {
          if (!this.disable) {
              if(this.idle) {
                  this.lockUser()
              }
          } else {

          }
      }
  },
  computed: {
    ...mapGetters({
        timer: 'getLockTimer',
        idle: 'getLockBool',
        disable: 'getLockDisable'
    }),
    isIdle() {
      return this.$store.state.idleVue.isIdle && this.$route.path.split('/')[2] != 'login';
    },
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  methods: {
    lockUser(){
        // set lock password if it's not set
        if(this.lockPassword === ''){
            this.showSetLockPasswordModal = true;
        } else {
            this.TRIGGER_LOCK({ status: true });
        }
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
    LockScreenMixin,
    twostep
  },
};
</script>
