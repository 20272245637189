import  request  from '@/utils/request'

export function index(params) {
    return request({
        url: '/finance/transactions',
        method: 'get',
        params: params
    })
}
export function show(id) {
    return request({
        url: `/finance/transactions/${id}`,
        method: 'get'
    })
}
export function store(data) {
    return request({
        url:  `/finance/transactions/${data.staff_id}`,
        method: 'post',
        data
    })
}
export function storeAdvanceSalary(data) {
  return request({
      url:  `/finance/advance-transactions/${data.staff_id}`,
      method: 'post',
      data
  })
}
export function update(data) {
    return request({
        url:  `/finance/transactions/${data.staff_id}/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(data) {
    return request({
        url:  `/finance/transactions/${data.staff_id}/${data.id}`,
        method: 'delete'
    })
}
