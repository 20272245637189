import { index, inventory, show, store, update, destroy, candidate, updateCandidate, getSteppChart, getSteppChartByCount, getSocialChart } from "@/api/recruitment/vacancy";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.vacancies);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.vacancies);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.vacancies);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.vacancy);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },

    getSteppChart({ commit }, id) {
        return new Promise((resolve, reject) => {
          getSteppChart(id)
                .then(res => {
                    commit("SET_STEP_CHART", res.data.result.data.step_chart);
                   resolve(res.data.result.data.step_chart);
                })
                .catch(err => {
                    // reject(err.response.data);
                });
        });
    },
    getSocialChart({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSocialChart(id)
              .then(res => {
                  commit("SET_SOCIAL_CHART", res.data.result.data.social_chart);
                 resolve(res.data.result.data.social_chart);
              })
              .catch(err => {
                  // reject(err.response.data);
              });
      });
  },
    getSteppChartByCount({ commit }, id) {
      return new Promise((resolve, reject) => {
        getSteppChartByCount(id)
              .then(res => {
                  commit("SET_STEP_CHART_BY_COUNT", res.data.result.data.step_chart_by_count);
                 resolve(res.data.result.data.step_chart_by_count);
              })
              .catch(err => {
                  // reject(err.response.data);
              });
      });
  },
    candidate({ commit }, object) {
      return new Promise((resolve, reject) => {
          candidate({vacancy_id: object.vacancy_id, params: object.params})
              .then(res => {
                  commit("SET_VACANCY_CANDIDATES_LIST", res.data.result.data.candidates);
                  commit("UPDATE_CANDIDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                  resolve(res.data.result.data.candidates);
              })
              .catch(err => {
                  // reject(err.response.data);
              });
      });
  },
    updateCandidate({ commit }, data) {
      return new Promise((resolve, reject) => {
          updateCandidate(data)
              .then(res => {
                commit("SET_VACANCY_CANDIDATES_LIST", res.data.result.data.candidates);
                resolve(res.data.result.data.candidates);
              })
              .catch(err => {
                  reject(err);
              });
      });
  },

    updateCandidatePagination({ commit }, candidate_pagination) {
        commit('UPDATE_CANDIDATE_PAGINATION', candidate_pagination)
    },


};
