export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    gender_ids: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    branch_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    company_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    department_ids: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    requirement_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    min_age: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    max_age: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    priority_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    position_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    graphic_ids: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    employmentType_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    language_ids: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    skill_ids: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    currency_ids: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    min_wage: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    max_wage: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    region_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    
    district_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

    description: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    status_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],

};
