import {i18n} from '@/utils/i18n';  
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: "id",
    },
    
    name: {
        show: true,
        title:  i18n.t('message.nameFull'),
        sortable: true,
        column: "name",
    },

    last_name: {
        show: true,
        title:  i18n.t('message.last_name'),
        sortable: true,
        column: "last_name",
    },

    phone_number: {
        show: true,
        title:  i18n.t('message.phone_number'),
        sortable: true,
        column: "phone_number",
    },
    
    gender_id: {
        show: true,
        title:  i18n.t('message.gender'),
        sortable: true,
        column: "gender_id",
    },

    status_id: {
        show: true,
        title:  i18n.t('message.status'),
        sortable: true,
        column: "status_id",
    },

    position_id: {
        show: true,
        title:  i18n.t('message.position'),
        sortable: true,
        column: "position_id",
    },

    department_id: {
        show: true,
        title:  i18n.t('message.position'),
        sortable: true,
        column: "department_id",
    },

    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },  
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }   

};
  