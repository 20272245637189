import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  manager_id: {
    show: true,
    title: i18n.t('message.manager'),
    sortable: true,
    column: 'manager_id'
  },
  header: {
    show: true,
    title: i18n.t('message.header'),
    sortable: true,
    column: 'header'
  },

  title: {
    show: true,
    title: i18n.t('message.title'),
    sortable: true,
    column: 'title'
  },

  is_show: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'is_show'
  },
  created_at: {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.updated_at'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
