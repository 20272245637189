import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import store from '@/store/index'
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {       
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, profile) => {
        state.model = profile
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));        
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    SET_STAFF_CONTACT:(state,staff_contact)=>{       
        state.staff_contact=staff_contact
    },
    EMPTY_STAFF_CONTACT:(state)=>{      
        state.staff_contact=JSON.parse(JSON.stringify({}));
    },
    SET_STAFF_EDUCATION:(state,staff_educations)=>(state.staff_educations=staff_educations),
    SET_STAFF_BONUSES:(state,staff_bonuses)=>(state.staff_bonuses=staff_bonuses),
    SET_STAFF_PENALTIES:(state,staff_penalties)=>(state.staff_penalties=staff_penalties),
    SET_STAFF_SKILLS: (state,staff_skills)=>(state.staff_skills=staff_skills),
    SET_STAFF_CHILDREN: (state,staff_children)=>(state.staff_children=staff_children),
    SET_STAFF_WORK_PLACES: (state, staff_work_places) => (state.staff_work_places = staff_work_places),
    SET_STAFF_WORK_HISTORY: (state, staff_work_history) => (state.staff_work_history = staff_work_history),
    SET_STAFF_CLIENT_CERTIFCATES: (state, staff_client_certificates) => (state.staff_client_certificates = staff_client_certificates),
    SET_STAFF_CLIENT_HEALTH_INFORMATION:(state, staff_client_health_information) => (state.staff_client_health_information = staff_client_health_information),
    SET_STAFF_REQUIREMENT:(state, staff_client_requirement) => (state.staff_client_requirement = staff_client_requirement),    
    SET_STAFF_STATUS_AND_REASON: (state,staff_status_and_reason)=>(state.staff_status_and_reason=staff_status_and_reason),
    SET_WORK_TYPE: (state,work_type)=>(state.work_type=work_type),
    SET_STAFF_DOCUMENTS: (state,staff_documents)=>(state.staff_documents=staff_documents),
    SET_STAFF_LANGUAGES:(state,staff_languages)=>(state.staff_languages=staff_languages),
    SET_STAFF_SALARIES:(state,staff_salaries)=>(state.staff_salaries=staff_salaries),
    SET_STAFF_CARDS:(state,staff_cards)=>(state.staff_cards=staff_cards),
    SET_STAFF_DOCUMENTS_FIVE: (state,staff_documents_five)=>(state.staff_documents_five=staff_documents_five),
};
