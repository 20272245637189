import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    type_expense : {
      show: true,
      title: i18n.t('message.type_expense'),
      sortable: true,
      column: 'type_expense'
    },
    money_amount : {
      show: true,
      title: i18n.t('message.amount'),
      sortable: true,
      column: 'amount'
    },
    branch : {
      show: true,
      title: i18n.t('message.branch'),
      sortable: true,
      column: 'branch'
    },
    datas : {
      show: true,
      title: i18n.t('message.datas'),
      sortable: true,
      column: 'datas'
    },
    account : {
      show: true,
      title: i18n.t('message.account'),
      sortable: true,
      column: 'account'
    },
    payment_type : {
      show: true,
      title: i18n.t('message.payment_type'),
      sortable: true,
      column: 'payment_type'
    },
    comment : {
      show: true,
      title: i18n.t('message.comment'),
      sortable: true,
      column: 'comment'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
