import {
    i18n
} from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: "id",
    },
    number: {
        show: true,
        title: i18n.t('message.car_number'),
        sortable: true,
        column: "number",
    },
    model: {
        show: true,
        title: i18n.t('message.car_model'),
        sortable: true,
        column: "model",
    },
    color: {
        show: true,
        title: i18n.t('message.car_color'),
        sortable: true,
        column: "color",
    },
    guest: {
        show: true,
        title: i18n.t('message.guest'),
        sortable: true,
        column: "guest",
    },
    total_visit: {
        show: true,
        title: i18n.t('message.total_visit'),
        sortable: true,
        column: "total_visit",
    },
    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },
};
