export const mutations = {
  SET_STATISTICS: (state, statistics) => {              
    state.statistics = statistics;        
  },
  SET_NEW_COME_STAFFS: (state, newcomestaffs) => {
    state.newcomestaffs = newcomestaffs
  },
  SET_COMPLETE_STAFF_PROFILE: (state, completestaffprofile) => {
    state.completestaffprofile = completestaffprofile
  },
  SET_STAFF_AGE_STATISTIC: (state, staffagestatistic) => {
    state.staffagestatistic = staffagestatistic
  },
  SET_STAFF_UPDATE: (state, staffupdate) => {
    state.staffupdate = staffupdate
  },
  SET_GENDER_STATISTIC: (state, genderstatistic) => {
    state.genderstatistic = genderstatistic
  },
  SET_TODAY_BIRTHDAYS: (state, todaybirthdays) => {
    state.todaybirthdays = todaybirthdays
  },
  SET_EXPECTED_BIRTHDAYS: (state, expectedbirthdays) => {
    state.expectedbirthdays = expectedbirthdays
  },
  SET_PAST_BIRTHDAYS: (state, pastbirthdays) => {
    state.pastbirthdays = pastbirthdays
  },
  SET_STAFF_BY_EDUCATION: (state, staffbyeducation) => {
    state.staffbyeducation = staffbyeducation
  },
  SET_STAFF_BY_REASON: (state, staffbyreason) => {
    state.staffbyreason = staffbyreason
  },
  SET_STAFF_WORK_TYPE: (state, staffworktype) => {
    state.staffworktype = staffworktype
  },
  SET_WORK_EXPERIENCE: (state, workexperience) => {
    state.workexperience = workexperience
  },
  SET_COMPLETE_PROFILE_THE_FILIALE: (state, completeprofilethefiliale) => {
    state.completeprofilethefiliale = completeprofilethefiliale
  },
  SET_EMPLOYEES_BY_REGION: (state, employessbyregion) => {
    state.employessbyregion = employessbyregion
  },
  SET_STAFF_FROM_CANDIDATE: (state, stafffromcandidate) => {
    state.stafffromcandidate = stafffromcandidate
  },
  SET_CANDIDATE: (state, candidate) => {
    state.candidate = candidate
  },
  SET_RELEASE_AND_RECEPTION: (state, releaseandreception) => {
    state.releaseandreception = releaseandreception
  },
  SET_SOURCE: (state, source) => {
    state.source = source
  },
  SET_WAGES: (state, wages) => {
    state.wages = wages
  },
  SET_AVERAGE_SALARY_BY_BRANCHES: (state, averagesalarybybranches) => {
    state.averagesalarybybranches = averagesalarybybranches
  },
  SET_STAFF_ATTENDENCES: (state, staffattendences) => {
    state.staffattendences = staffattendences
  },
  SET_FACE_ID_REGISTRATION: (state, faceidregistration) => {
    state.faceidregistration = faceidregistration
  },
  SET_ATTEND_STAFF_WEEK: (state, attendstaffweek) => {
    state.attendstaffweek = attendstaffweek
  },
  SET_VISITORS: (state, visitors) => {
    state.visitors = visitors
  },
  SET_TOP_MANAGERS: (state, topmanagers) => {
    state.topmanagers = topmanagers
  },
  SET_STAFF_TELEGRAM_INFORMATION: (state, stafftelegraminformation) => {
    state.stafftelegraminformation = stafftelegraminformation
  },
  SET_EMPLOYEE_KPP: (state, employeekpp) => {
    state.employeekpp = employeekpp
  },
  SET_TRANSPORT_KPP: (state, transportkpp) => {
    state.transportkpp = transportkpp
  },
  SET_REASON_OPERATIONS: (state, reasonbyoperation) => {
    state.reasonbyoperation = reasonbyoperation
  },
  SET_TMS_STATISTIC: (state, tmsstatistic) => {
    state.tmsstatistic = tmsstatistic
  },
  SET_TMS_LIST: (state, tmslist) => {
    state.tmslist = tmslist
  },
  SET_TOP_TOILET_ATTENDANTS: (state, toptoiletattendants) => {
    state.toptoiletattendants = toptoiletattendants
  },
  SET_STAFF_TOTAL_WORK: (state, topstaffwithtotalwork) => {
    state.topstaffwithtotalwork = topstaffwithtotalwork
  },
  SET_HAS_SALARY_STAFFS: (state, hasSalayStaffs) => {
    state.hasSalayStaffs = hasSalayStaffs
  },
  SET_REQUEST_FOR_TODAY: (state, requestsForToday) => {
    state.requestsForToday = requestsForToday
  },
  SET_STAFF_BY_MOOD: (state, staffbymood) => {
    state.staffbymood = staffbymood
  },
  SET_SITUATION_FOR_TODAY: (state, situationfortoday) => {
    state.situationfortoday = situationfortoday
  }
};
