import { columns } from './properties/columns'
import { index_columns } from './properties/index_columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    social_chart: [],
    step_chart: [],
    step_chart_by_count: [],
    vacancy_candidate_list:[],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    index_columns: index_columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,
    candidate_pagination:JSON.parse(JSON.stringify(pagination))
};
