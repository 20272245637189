import request from '@/utils/request'

export function inventory(params) {
  return request({
      url: 'staffs/family_status/inventory',
      method: 'get',
      params
  })
}

