import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    manager : {
      show: true,
      title: i18n.t('message.responsible'),
      sortable: true,
      column: 'manager'
    },
    organization : {
      show: true,
      title: i18n.t('message.organizations'),
      sortable: true,
      column: 'organization'
    },
    purpose : {
      show: true,
      title: i18n.t('message.business_trip_purpose'),
      sortable: true,
      column: 'purpose'
    },
    tasks : {
      show: true,
      title: i18n.t('message.business_trip_tasks'),
      sortable: true,
      column: 'tasks'
    },
    start_date_time : {
        show: true,
        title: i18n.t('message.start_date_time'),
        sortable: true,
        column: 'start_date_time'
    },
    end_date_time : {
      show: true,
      title: i18n.t('message.end_date_time'),
      sortable: true,
      column: 'end_date_time'
    },
    staffs : {
      show: true,
      title: i18n.t('message.staff'),
      sortable: true,
      column: 'staffs'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
