export const rules = {
    guest_car_type_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    },],
    guest_car_model_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    },]    
};
