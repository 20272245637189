<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.transport')"
      filterable
      clearable
      :multiple="multiple"
      :size="size"
      class="d-block"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(transport, index) in transports"
        :key="'transports-' + index"
        :label="transport.number + '-' + transport.name"
        :value="transport.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.transports && this.transports.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      transports: "transport/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "transport/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      if (e) {
        let transport = _.find(this.transports, ["id", e]);
        this.$emit("staffId", transport.staff_id);
      } else {
        this.$emit("staffId", null);
      }
    },
  },
};
</script>
