import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/settings/vacation-managements',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/settings/vacation-managements/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/settings/vacation-managements/${id}`,
      method: 'get'
  })
}
export function getStaffInfo(id) {
  return request({
      url: `/settings/vacation-management/staff/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/settings/vacation-managements',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/settings/vacation-managements/${data.id}`,
      method: 'put',
      data
  })
}
export function updateStatus(data) {
  return request({
      url: `/settings/vacation-management/${data.id}/update-status`,
      method: 'post',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/settings/vacation-managements/${id}`,
      method: 'delete',
  })
}
