import { 
    show,
    addStaffContact,
    getStaffContact,
    addStaffEducation, 
    getStaffEducation, 
    updateStaffEducation, 
    deleteStaffEducation, 
    addStaffSkill, 
    updateStaffSkill, 
    getStaffSkill, 
    deleteStaffSkill, 
    addStaffChildren, 
    updateStaffChildren, 
    getStaffChildren, 
    deleteStaffChildren, 
    getStaffStatusAndReason,
    updateStaffStatusAndReason,
    getStaffWorkType,
    updateStaffWorkType,
    getStaffDocument,
    uploadStaffDocument,
    getStaffDocumentFive,
    uploadStaffDocumentFive,
    deleteStaffDocument, 
    addStaffWorkPlaces, 
    getStaffWorkPlaces, 
    getStaffWorkHistory,
    uploadStaffWorkPlaces,  
    deleteStaffWorkPlaces, 
    deleteStaffWorkHistory,
    addStaffClientCertificates, 
    getStaffClientCertificates, 
    uploadStaffClientCertificates, 
    deleteStaffClientCertificates, 
    addStaffClientHealthInformation, 
    getStaffClientHealthInformation, 
    uploadStaffClientHealthInformation, 
    deleteStaffClientHealthInformation, 
    getStaffLanguage, 
    addStaffLanguage,
    updateStaffLanguage,
    deleteStaffLanguage,
    addStaffRequirement, 
    getStaffRequirement, 
    updateStaffRequirement, 
    deleteStaffRequirement,
    getStaffSalary,
    addStaffSalary,
    deleteStaffSalary,
    addStaffCard,
    updateStaffCard,
    deleteStaffCard,
    getStaffCard,
    getBonuses,
    getPenalties,
    updateStaffSalary
} from "@/api/staff/profile";

export const actions = {  

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.staff);                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    addStaffContact({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffContact(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffContact({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffContact(staff_id)
                .then(res => {
                    if(res.data.result.data.contact.id)
                    {
                        commit('SET_STAFF_CONTACT', res.data.result.data.contact);
                    }
                    else{                        
                        commit('EMPTY_STAFF_CONTACT');
                    }                    
                    resolve(res);
                })
                .catch(err => {
                    commit('EMPTY_STAFF_CONTACT');
                    reject(err);
                });
        });
    },



    addStaffEducation({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffEducation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffEducation({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffEducation(staff_id)
                .then(res => {
                    commit('SET_STAFF_EDUCATION', res.data.result.data.educations);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getBonuses({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getBonuses(staff_id)
                .then(res => {
                    commit('SET_STAFF_BONUSES', res.data.result.data.bonuses);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getPenalties({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getPenalties(staff_id)
                .then(res => {
                    commit('SET_STAFF_PENALTIES', res.data.result.data.penalties);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateStaffEducation({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffEducation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffEducation({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffEducation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },



    addStaffSkill({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffSkill(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffSkill({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffSkill(staff_id)
                .then(res => {
                    commit('SET_STAFF_SKILLS', res.data.result.data.skills);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffSkill({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffSkill(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffSkill({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffSkill(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },



    addStaffChildren({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffChildren(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffChildren({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffChildren(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_CHILDREN', res.data.result.data.children);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffChildren({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffChildren(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffChildren({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffChildren(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },



    getStaffStatusAndReason({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffStatusAndReason(staff_id)
                .then(res => {
                    commit('SET_STAFF_STATUS_AND_REASON', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffStatusAndReason({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffStatusAndReason(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffWorkType({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffWorkType(staff_id)
                .then(res => {
                    commit('SET_WORK_TYPE', res.data.result.data.work_type);
                    resolve(res);
                })
                .catch(err => {                    
                    reject(err);
                });
        });
    },

    updateStaffWorkType({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffWorkType(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },



    getStaffDocument({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffDocument(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_DOCUMENTS', res.data.result.data.documents);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    uploadStaffDocument({ commit }, data) {
        return new Promise((resolve, reject) => {
            uploadStaffDocument(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffDocumentFive({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffDocumentFive(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_DOCUMENTS_FIVE', res.data.result.data.documents_five);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    uploadStaffDocumentFive({ commit }, data) {
        return new Promise((resolve, reject) => {
            uploadStaffDocumentFive(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffDocument({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffDocument(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffWorkPlaces({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffWorkPlaces(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffWorkPlaces({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffWorkPlaces(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_WORK_PLACES', res.data.result.data.work_places);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    getStaffWorkHistory({ commit }, staff_id) {
      return new Promise((resolve, reject) => {
          getStaffWorkHistory(staff_id)
              .then(res => {                   
                  commit('SET_STAFF_WORK_HISTORY', res.data.result.data.work_history);
                  resolve(res);
              })
              .catch(err => {
                  reject(err);
              });
      });
  },
  deleteStaffWorkHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
        deleteStaffWorkHistory(data)
            .then(res => {                    
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
  },


    uploadStaffWorkPlaces({ commit }, data) {
        return new Promise((resolve, reject) => {
            uploadStaffWorkPlaces(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffWorkPlaces({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffWorkPlaces(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffClientCertificates({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffClientCertificates(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffClientCertificates({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffClientCertificates(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_CLIENT_CERTIFCATES', res.data.result.data.client_certificates);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    uploadStaffClientCertificates({ commit }, data) {
        return new Promise((resolve, reject) => {
            uploadStaffClientCertificates(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffClientCertificates({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffClientCertificates(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffClientHealthInformation({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffClientHealthInformation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffClientHealthInformation({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffClientHealthInformation(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_CLIENT_HEALTH_INFORMATION', res.data.result.data.client_health_information);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    uploadStaffClientHealthInformation({ commit }, data) {
        return new Promise((resolve, reject) => {
            uploadStaffClientHealthInformation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffClientHealthInformation({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffClientHealthInformation(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    addStaffLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffLanguage(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffLanguage({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffLanguage(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_LANGUAGES', res.data.result.data.client_languages);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffLanguage(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffLanguage({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffLanguage(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffRequirement({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffRequirement(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffRequirement({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffRequirement(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_REQUIREMENT', res.data.result.data.client_requirements);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffRequirement({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffRequirement(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffRequirement({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffRequirement(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffSalary({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffSalary(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffSalary({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffSalary(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffSalary({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffSalary(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_SALARIES', res.data.result.data.salaries);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffSalary({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffSalary(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    addStaffCard({ commit }, data) {
        return new Promise((resolve, reject) => {
            addStaffCard(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getStaffCard({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getStaffCard(staff_id)
                .then(res => {                   
                    commit('SET_STAFF_CARDS', res.data.result.data.cards);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateStaffCard({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStaffCard(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteStaffCard({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteStaffCard(data)
                .then(res => {                    
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
