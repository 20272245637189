import request from '@/utils/request'

export function structure() {
  return request({
      url: '/staffs/structure',
      method: 'get'      
  })
}
export function getCompanyStructure(company_id) {
    return request({
        url: `/staffs/structure/companies/${company_id}`,
        method: 'get'      
    })
}

export function getCompanyStaffList(company_id) {
    return request({
        url: `/staffs/structure/companies/list/${company_id}`,
        method: 'get'      
    })
}

export function getBranchStaffList(branch_id) {
    return request({
        url: `/staffs/structure/branches/list/${branch_id}`,
        method: 'get'      
    })
}

export function getDepartmentStaffList(department_id) {
    return request({
        url: `/staffs/structure/departments/list/${department_id}`,
        method: 'get'      
    })
}
  
export function updateStructure(data) {
  return request({
      url: '/staffs/structure/update',
      method: 'post',
      data
  })
}
