<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.sort_panel')"
      filterable
      clearable
      :disabled="disabled"
      :size="size"
      class="d-block"
      :multiple="multiple"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option v-can="'SelectDashboardController@sort_panel'"
        :label="$t('message.sort_panel')"
        :value="1"
      ></el-option>
      <el-option v-can="'SelectDashboardController@recruiting'"
          :label="$t('message.recruiting')"
        :value="2"
      ></el-option>
      <el-option v-can="'SelectDashboardController@finance'"
          :label="$t('message.finance')"
        :value="3"
      ></el-option>
      <el-option v-can="'SelectDashboardController@kpp'"
          :label="$t('message.KPP')"
        :value="4"
      ></el-option>
      <el-option v-can="'SelectDashboardController@indicators'"
          :label="$t('message.indicators')"
        :value="5"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false      
    },
    company_id: {
      default: null,
    },
    items: {
      default: null
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.branches && this.branches.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
    }),
    dispatch(e) {
      this.selected = e;
      this.$emit("sortmethods", e);

    },
  },
};
</script>
