export const mutations = {
    SET_LIST: (state, structures) => {              
        state.list = structures;        
    },
    SET_STAFF_LIST:(state,staff_list)=>{
        state.staff_list=staff_list
    },
    SET_STATE: (state, payload) => {
      state.mockdata = payload;
    },
}
