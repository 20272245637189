import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    company_id : {
        show: true,
        title: i18n.t('message.company'),
        sortable: true,
        column: 'company_id'
    },
    branch_id : {
        show: true,
        title: i18n.t('message.branch'),
        sortable: true,
        column: 'branch_id'
    },
    department_id : {
        show: true,
        title: i18n.t('message.department'),
        sortable: true,
        column: 'department_id'
    },
    graphic_id : {
        show: true,
        title: i18n.t('message.graphic'),
        sortable: true,
        column: 'graphic_id'
    },
    staff_id : {
        show: true,
        title: i18n.t('message.staff'),
        sortable: true,
        column: 'staff_id'
    }, 
    name: {
        show: true,
        title: i18n.t('message.bonus_name'),
        sortable: true,
        column: 'name'
    },
    amount : {
        show: true,
        title: i18n.t('message.amount'),
        sortable: true,
        column: 'amount'
    }, 
    reason_id : {
        show: true,
        title: i18n.t('message.reason'),
        sortable: true,
        column: 'reason_id'
    }, 
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    }, 
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
