import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  reason: {
    show: true,
    title: i18n.t('message.reason'),
    sortable: true,
    column: 'reason'
  },
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status'
  },
  work_time: {
    show: true,
    title: i18n.t('message.work_times'),
    sortable: true,
    column: 'work_time'
  },
  date: {
    show: true,
    title: i18n.t('message.datas'),
    sortable: true,
    column: 'date'
  },
  created_at : {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
},
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
