import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff : {
      show: true,
      title: i18n.t('message.staff'),
      sortable: true,
      column: 'staff'
    },
   
    month : {
      show: true,
      title: i18n.t('message.monthly'),
      sortable: true,
      column: 'month'
    },
    day_count : {
      show: true,
      title: i18n.t('day_count'),
      sortable: true,
      column: 'day_count'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
