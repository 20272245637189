<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.device')"
      filterable
      clearable
      :size="size"
      class="d-block"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(device_type, index) in devices"
        :key="'devices-' + index"
        :label="device_type.device_name"
        :value="device_type.serial_number"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    // multiple:{
    //     type: Boolean,
    //     default: false,
    // }
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.devices && this.devices.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      devices: "device/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "device/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
